<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="80%">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-tabs type="border-card" value="base">
        <el-tab-pane name="base" label="基本信息">
          <el-row class="twice padding-top-xs">
            <el-form-item label="资产分类" prop="categoryCode">
              <l-select v-model="dataInfo.categoryCode" :remote="$api.assetCategoryList"></l-select>
            </el-form-item>
            <el-form-item label="资产编号" prop="assetCode">
              <el-input v-model="dataInfo.assetCode" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="资产名称" prop="assetName">
              <el-input v-model="dataInfo.assetName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="资产类型" prop="assetType">
              <l-select v-model="dataInfo.assetType" :data="$constant.assetTypeList"></l-select>
            </el-form-item>
            <el-form-item label="是否可借" prop="borrowFlag">
              <el-switch v-model="dataInfo.borrowFlag"> </el-switch>
            </el-form-item>
            <el-form-item label="采购人" prop="purchaseUserName">
              <el-input v-model="dataInfo.purchaseUserName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="采购时间" prop="purchaseTime">
              <l-datepicker v-model="dataInfo.purchaseTime"></l-datepicker>
            </el-form-item>
            <el-form-item label="备注信息" prop="remarks">
              <el-input v-model="dataInfo.remarks" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="实体图片" prop="imgUrl"> </el-form-item>
            <el-form-item label="资产二维码" prop="assetName" v-if="dataInfo.assetId">
              <el-image style="width: 200px; height: 200px" :src="dataInfo.qrImg" :fit="fit"></el-image>
            </el-form-item>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="gps" label="更多信息">
          <el-row class="twice padding-top-xs">
            <el-form-item label="资产过期日期" prop="expiredDate">
              <l-datepicker v-model="dataInfo.expiredDate"></l-datepicker>
            </el-form-item>
            <el-form-item label="设备规格型号" prop="deviceModel">
              <el-input v-model="dataInfo.deviceModel" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="设备厂商" prop="deviceVendor">
              <el-input v-model="dataInfo.deviceVendor" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="购建原值" prop="price">
              <el-input v-model="dataInfo.price" autocomplete="off">
                <span slot="suffix">元</span>
              </el-input>
            </el-form-item>
            <el-form-item label="预计残值" prop="remainPrice">
              <el-input v-model="dataInfo.remainPrice" autocomplete="off">
                <span slot="suffix">元</span>
              </el-input>
            </el-form-item>
            <el-form-item label="折旧月份" prop="depreciationMonth">
              <el-input v-model="dataInfo.depreciationMonth" autocomplete="off">
                <span slot="suffix">个月</span>
              </el-input>
            </el-form-item>
            <el-form-item label="变卖价格" prop="sellPrice">
              <el-input v-model="dataInfo.sellPrice" autocomplete="off">
                <span slot="suffix">元</span>
              </el-input>
            </el-form-item>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      activeName: 'base',
      dataRules: {
        assetName: [{ required: true, trigger: 'blur', message: '资产名称不能为空' }],
        categoryCode: [{ required: true, trigger: 'blur', message: '资产分类不能为空' }],
        assetType: [{ required: true, trigger: 'blur', message: '资产类型不能为空' }]
      }
    }
  },
  mounted() {
    console.log(this.dataInfo.qrImg)
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    uploaded(res) {
      Object.assign(this.dataInfo, {
        imgUrlList: res
      })
      // this.$set(this.dataInfo)
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/asset/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
